import React, { ReactElement } from "react"
import Header from '../components/header'
import Footer from "../components/footer";
import Layout from "../components/layout";

const Txt: React.FunctionComponent = (props) => {
  return (
    <p className="py-2">{props.children}</p>
  );
}

function Privacy(): ReactElement {
  return (
    <>
      <Header page={'Privacy'} />
      <Layout>
        <div className="py-2 px-2 md:py-10 md:px-32 text-md md:text-xl text-center font-bold">
          <p className="pb-4 text-3xl">Privacy Policy</p>
          <Txt>You trust us to be respectful with your information. This Privacy Policy is meant to help you understand what data we collect, why we collect it, and what we do with it.</Txt>
          <Txt>- We don't collect any personally identifiable information about our users.</Txt>
          <Txt>- Our apps don't collect information such as the user's name, address, phone number or email address.</Txt>
          <Txt>- We don't use technology to collect and disclose to a third party a user's personally information such as name, gender, age, address, email address, phone number, etc.</Txt>
          <Txt>- Our apps don't use or collect a user's precise geographic location.</Txt>
          <Txt>- Our apps may contain information about our other apps with an external link to those apps on the applicable app store. This link may be found on the main title screen by clicking "More Games." Once you click this link, you will be taken to the developer's app store page.</Txt>
          <Txt>- Our apps may contain In-App Purchase to enable additional content. These In-App Purchases are purchased once, and are found on a secured screen.</Txt>
          <Txt>- We may make small, inconsequential changes to this policy with our without notice to you, so you're encouraged to review the policy from time to time.</Txt>
          <Txt>- Our apps have been designed with the guidelines of the United States Federal Trade Commission (FTC) in mind, as expressed in the Children's Online Privacy Protection rule (COPPA) and strive to meet all duties and obligations under the rule of law. The rule requires operators of online services, including interactive mobile apps, to provide notice and get parental consent prior to collecting information from children under 13. The FTC works for consumers to prevent fraudulent, deceptive, and unfair business practices and to provide information to help spot, stop, and avoid them. If you have any questions or concerns about our privacy policy, please contact us.</Txt>
        </div>
      </Layout>
      <Footer />
    </>
  )
}

export default Privacy
